<script setup>
import { Plus } from '@element-plus/icons-vue'
import { onMounted, ref, getCurrentInstance, reactive, nextTick } from 'vue'
import dayjs from "dayjs"
import { ElMessage, ElNotification } from 'element-plus'
import activity from "./activity/index.vue"

const { appContext } = getCurrentInstance()

let channelData = ref()

const handleChangeStatus = (e, row) => {
  let id = row.id
  if (e == 1) {
    appContext.config.globalProperties.$api.payment.paymenEditor(id, { status: 1 }).then(res => {
      if (res.data.status == 200) {
        ElMessage({
          message: '启用成功',
          type: 'success',
        })
      } else {
        e = 0
        ElMessage({
          message: '启用失败',
          type: 'error',
        })
      }
    })
  } else {
    appContext.config.globalProperties.$api.payment.paymenEditor(id, { status: 0 }).then(res => {
      if (res.data.status == 200) {
        ElMessage({
          message: '禁用成功',
          type: 'success',
        })
      } else {
        e = 1
        ElMessage({
          message: '禁用失败',
          type: 'error',
        })
      }
    })
  }
}

// 新增
let newDialog = ref(false)
let newChannelData = ref({
  name: "",
})
// 实例化
const ruleNewChannel = ref()
const rules = reactive({
  name: [
    {
      required: true,
      message: '请输入渠道名称',
      trigger: 'blur'
    }
  ],
  code: [
    {
      required: true,
      message: '请输入渠道编码',
      trigger: 'blur'
    }
  ],
  settlement_type: [
    {
      required: true,
      message: '请输入结算周期',
      trigger: 'blur'
    }
  ],
  deduct_type: [
    {
      required: true,
      message: '请选择是否从商户账款扣款',
      trigger: 'change',
    }
  ],
  status: [
    {
      required: true,
      message: '请选择是否启用',
      trigger: 'change',
    }
  ],
  payment: [
    {
      required: true,
      message: '请选择支付方式',
      trigger: 'change',
    }
  ],
  app_id: [
    {
      required: true,
      message: 'AppID不能为空',
      trigger: 'blur'
    }
  ],
  app_secret: [
    {
      required: true,
      message: 'AppSecret不能为空',
      trigger: 'blur'
    }
  ],
  org_id: [
    {
      required: true,
      message: 'OrgID不能为空',
      trigger: 'blur'
    }
  ],
  private_key: [
    {
      required: true,
      message: 'PrivateKEY不能为空',
      trigger: 'change',
    }
  ],
  public_key: [
    {
      required: true,
      message: 'PublicKEY不能为空',
      trigger: 'change',
    }
  ],
  mch_id: [
    {
      required: true,
      message: 'MuchID不能为空',
      trigger: 'blur'
    }
  ],
  key: [
    {
      required: true,
      message: 'KEY不能为空',
      trigger: 'blur'
    }
  ],
  cert_path: [
    {
      required: true,
      message: 'CertPath不能为空',
      trigger: 'change',
    }
  ],
  key_path: [
    {
      required: true,
      message: 'KEYPath不能为空',
      trigger: 'change',
    }
  ],
  app_secret_cert: [
    {
      required: true,
      message: '应用私钥不能为空',
      trigger: 'change',
    }
  ],
  app_public: [
    {
      required: true,
      message: '应用公钥证书不能为空',
      trigger: 'change',
    }
  ],
  alipay_public: [
    {
      required: true,
      message: '支付宝公钥证书不能为空',
      trigger: 'change',
    }
  ],
  alipay_root: [
    {
      required: true,
      message: '支付宝根证书不能为空',
      trigger: 'change',
    }
  ],
  cost: [
    {
      required: true,
      message: "费率不能为空",
      trigger: 'blur'
    },
    {
      type: 'number',
      message: '费率必须是数字',
    },
    {
      min: 0,
      max: 100,
      message: '取值范围0-100',
      trigger: 'blur'
    }
  ],
  fee_rate: [
    {
      required: true,
      message: "扣费比例率不能为空",
      trigger: 'blur'
    },
    {
      min: 0,
      max: 100,
      message: '取值范围0-100',
      trigger: 'blur'
    }
  ]
})
// 支付方式
let paymethod = ref()
const handleNewPay = () => {
  newDialog.value = !newDialog.value
}
const handleNewChannelData = async (newChannel) => {
  if (!newChannel) return
  await newChannel.validate((valid) => {
    if (valid) {
      restores()
      addData(newChannelData.value)
    }
  })
}

// 取消、确认恢复原结构
const restores = () => {
  let arr = []
  let temp = newChannelData.value.payment_methods
  paymethod.value.forEach(element => {
    temp.forEach(item => {
      if (item == element.id) {
        arr.push(element)
      }
    })
  });
  newChannelData.value.payment_methods = arr
}

const addData = (temp) => {
  appContext.config.globalProperties.$api.payment.paymentAdd(temp).then(res => {
    if (res.status == 200) {
      newDialog.value = !newDialog.value
      ElMessage({
        message: '添加成功',
        type: 'success',
      })
      queryPayment()
    } else {
      newDialog.value = !newDialog.value
      ElMessage({
        message: '添加失败，请稍后重试',
        type: 'error',
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 配置
let configDialog = ref(false)
let ruleConfig = ref()
let configDate = ref({})

// 编辑配置
const handleEditorConfig = (data) => {
  configDate.value = { ...data }
  configDialog.value = !configDialog.value
}
const handleSeveConfig = async (config) => {
  if (!config) return
  await config.validate((valid, fields) => {
    if (valid) {
      let tempData = {
        code: configDate.value.code,
        config: configDate.value.config
      }
      sendEditoConfig(configDate.value.id, tempData)
    } else {
      console.log("error", fields);
    }
  })
}
// 发生编辑配置请求
const sendEditoConfig = (id, data) => {
  appContext.config.globalProperties.$api.payment.paymenEditor(id, data).then(res => {
    if (res.data.status == 200) {
      ElMessage({
        message: '修改成功',
        type: 'success',
      })
      configDialog.value = !configDialog.value
      queryPayment()
    } else {
      ElMessage({
        message: '修改失败，请稍后重试',
        type: 'error',
      })
      configDialog.value = !configDialog.value
    }
  }).catch(err => {
    console.log(err.response.data.error.fields);
    ElMessage({
      message: err.response.data.error.fields,
      type: 'error',
    })
  })
}



// 编辑
const editorDialog = ref(false)
const ruleModify = ref()
let editorData = ref()
let settlement = reactive([
  {
    settlement_type: "D0"
  },
  {
    settlement_type: "D1"
  },
  {
    settlement_type: "T0"
  },
  {
    settlement_type: "T1"
  },
])
const handleEditor = (data) => {
  let temp = data.payment_methods.map(item => {
    return item.id || item
  })
  data.payment = paymethod.value
  // 支付数据收集
  data.pay_methods = data.payment_methods
  // 下拉选择
  data.payment_methods = temp
  // 恢复结构
  let arr = []
  let selectbar = data.payment_methods
  data.pay_methods.forEach(element => {
    selectbar.forEach(item => {
      if (item == element.id) {
        arr.push(element)
      }
    })
  });
  data.payment_tabbar = arr
  editorData.value = { ...data }
  editorDialog.value = !editorDialog.value
  queryPayment()
}
// 取消、确认恢复原结构
const restore = () => {
  let arr = []
  let temp = editorData.value.payment_methods
  paymethod.value.forEach(element => {
    temp.forEach(item => {
      if (item == element.id) {
        arr.push(element)
      }
    })
  });
  editorData.value.payment_methods = arr
}
const handleSeveModify = async (modify) => {
  if (!modify) return
  await modify.validate((valid, fields) => {
    if (valid) {
      restore()
      sendEditor(editorData.value)
      editorDialog.value = !editorDialog.value
    } else {
      restore()
      ElNotification({
        title: 'Error',
        message: fields,
        type: 'error',
      })
    }
  })
}
const sendEditor = (data) => {
  let editorID = data.id
  let dataFormat = {
    code: data.code,
    deduct_type: data.deduct_type,
    id: data.id,
    name: data.name,
    settlement_type: data.settlement_type,
    sort: data.sort,
    status: data.status,
    weight: data.weight,
  }

  // 判断开启是否从商户账款扣款选项
  if (dataFormat.deduct_type != 0) {
    dataFormat.fee_rate = data.fee_rate
  }

  //  整理格式
  let commpent = []
  data.payment_tabbar.map((item) => {
    let results = {
      cost: item.pivot.cost,
      id: item.id
    }
    commpent.push(results)
  })
  dataFormat.payment_methods = commpent

  // 发送请求
  appContext.config.globalProperties.$api.payment.paymenEditor(editorID, dataFormat).then(res => {
    if (res.status == 200) {
      ElMessage({
        message: '修改成功',
        type: 'success',
      })
      queryPayment()
    } else {
      ElMessage({
        message: '修改失败,请稍后重试',
        type: 'error',
      })
      queryPayment()
    }
  }).catch(err => {
    ElNotification({
      title: `错误提示:${err.response.status}`,
      message: err.response.data.error.fields,
      type: 'error',
    })

  })
}

// 下拉菜单选中值发生变化时触发
const hendleSelectSwitch = (val) => {
  let payment = editorData.value.payment
  let selectlist = editorData.value.payment_tabbar
  let temp
  val.forEach(item => {
    payment.forEach(element => {
      if (item == element.id) {
        temp = element
      }
    })
  })

  // 判断有没有pivot
  let len = editorData.value.payment_methods
  if (len.length > 0) {
    if (temp.pivot == undefined || temp.pivot == null || temp.pivot == "") {
      temp.pivot = {}
      if (temp.id == 3 && temp.code == 'unionpay' || temp.id == 4 && temp.code == 'bankcard') {
        temp.pivot.cost = { ...temp.cost }
      } else {
        temp.pivot.cost = temp.cost
      }
    }
    // 判断是否有相同项
    if (JSON.stringify(selectlist).indexOf(JSON.stringify(temp.code)) == -1) {
      selectlist.push(temp)
    }
  }
}
// 移除tag时触发
const hendleRemoveSlect = (val) => {
  let newmethods = editorData.value.payment_tabbar
  newmethods.splice(newmethods.findIndex(item => item.id == val), 1)
}

let pageinfo = reactive({
  total: 0
})

let isloading = ref(true)

// 查询支付渠道列表
const queryPayment = () => {
  isloading.value = true
  appContext.config.globalProperties.$api.payment.paymentList().then(res => {
    if (res.data.status == 200) {
      channelData.value = res.data.data
      pageinfo.total = res.data.data.length
      isloading.value = false
    }
  })
  queryPayMethods()
}
// 查询支付方式
const queryPayMethods = () => {
  appContext.config.globalProperties.$api.payment.paymethods().then(res => {
    paymethod.value = res.data.data
  })
}

onMounted(() => {
  queryPayment()
})


/* 活动报名 */

const activityRef = ref()

// activityRef
const handleactivity = (row) => {
  nextTick(() => {
    activityRef.value.open(row)
  })
}


// 设置商户服务费
const handleSetChannelCharge = (params) => {
  appContext.config.globalProperties.$api.merchants.setChannelCharge(params.id, {
    api_charge_status: params.api_charge_status
  }).then(res => {
    if (res.status == 200) {
      ElMessage({ message: '操作成功', type: 'success' })
    } else {
      ElMessage.error('操作失败')
    }
  }).catch(err => {
    if (err.response.data.status == 500 && err.response.data.error.message) {
      ElMessage({ message: err.response.data.error.message, type: 'warning' })
    } else {
      ElMessage.error('服务器内部错误')
    }
  })
}


</script>

<template>
  <div class="topbtn">
    <el-button type="primary" :icon="Plus" @click="handleNewPay">新增支付渠道</el-button>
  </div>
  <!-- tabularData -->
  <el-table :data="channelData" style="width: 100%" v-loading="isloading" size="small">
    <el-table-column label="序号" width="80" type="index" align="center" />
    <el-table-column label="渠道名字" prop="name" align="center" />
    <el-table-column label="渠道编号" prop="code" align="center" />
    <el-table-column label="结算周期" prop="settlement_type" align="center" width="100" />
    <el-table-column label="从商户账款扣款" prop="deduct_type" align="center">
      <template #default="scoped">
        <div v-if="scoped.row.deduct_type == 1" class="tag-clearance">
          <el-tag effect="dark">是</el-tag>
          <el-tag type="danger" effect="plain">{{ scoped.row.fee_rate }}%</el-tag>
        </div>
        <div v-else>
          <el-tag type="danger" effect="dark">否</el-tag>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="状态" prop="status" align="center">
      <template #default="scoped">
        <el-switch v-model="scoped.row.status" inline-prompt size="large" active-text="启用" inactive-text="禁用"
          :active-value="1" :inactive-value="0" @change="handleChangeStatus($event, scoped.row)" />
      </template>
    </el-table-column>
    <el-table-column label="商户服务费" width="130" align="center">
      <template #default="scope">
        <el-switch v-model="scope.row.api_charge_status" :active-value='1' :inactive-value='0' active-text="启用"
          size="large" inactive-text="关闭" inline-prompt @click="handleSetChannelCharge(scope.row)" />
      </template>
    </el-table-column>

    <el-table-column label="排序" prop="sort" align="center" width="100" />
    <el-table-column label="优先级" prop="weight" align="center" width="100" />
    <el-table-column label=" 创建时间" prop="created_at" align="center">
      <template #default="scope">
        <span>{{ dayjs(scope.row.created_at).format("YYYY-MM-DD") }}</span>
      </template>
    </el-table-column>
    <el-table-column label="操作" align="center">
      <template #default="scope">
        <div class="control">
          <el-link :underline="false" type="primary" @click="handleEditor(scope.row)">编辑</el-link>
          <el-link :underline="false" type="primary" @click="handleEditorConfig(scope.row)"
            v-if="scope.row.id == 1 || scope.row.id == 2 || scope.row.id == 3 || scope.row.id == 4">配置信息</el-link>
          <el-link :underline="false" type="primary" @click="handleactivity(scope.row)"
            v-if="scope.row.code == 'lakala'">活动列表</el-link>
        </div>
      </template>
    </el-table-column>
    <template #empty>
      <el-empty description="空数据" :image-size="200" />
    </template>
  </el-table>
  <div class="pagination">
    <el-pagination background layout=" total, prev, pager, next" :total=pageinfo.total :page-size="20" />
  </div>
  <!-- tabularData END-->

  <!-- New PayChannel-->
  <el-dialog v-model="newDialog" title="新增支付渠道">
    <div class="dialog">
      <el-form ref="ruleNewChannel" :model="newChannelData" :rules="rules" label-position="right" label-width="150px">
        <el-form-item label="渠道名称：" prop="name">
          <el-input v-model="newChannelData.name" placeholder="请输入渠道名称" />
        </el-form-item>
        <el-form-item label="编码：" prop="code">
          <el-input v-model="newChannelData.code" placeholder="请输入渠道编码" />
        </el-form-item>
        <el-form-item label="结算周期：" prop="settlement_type">
          <el-select v-model="newChannelData.settlement_type" placeholder="请选择结算周期" style="width:100%">
            <el-option v-for="item in settlement" :key="item.index" :label="item.settlement_type"
              :value="item.settlement_type" />
          </el-select>
        </el-form-item>
        <el-form-item label="从商户账款扣款：" prop="deduct_type">
          <el-radio-group v-model="newChannelData.deduct_type">
            <el-radio label=1 size="large">是</el-radio>
            <el-radio label=0 size="large">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="扣费比例(%)：" prop="fee_rate" v-if="newChannelData.deduct_type == 1">
          <el-input v-model="newChannelData.fee_rate" placeholder="请输入扣费比例" />
        </el-form-item>
        <el-form-item label="状态：" prop="status">
          <el-radio-group v-model="newChannelData.status">
            <el-radio label=1 size="large">启用</el-radio>
            <el-radio label=0 size="large">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序：">
          <el-input v-model="newChannelData.sort" placeholder="请输入排序" />
        </el-form-item>
        <el-form-item label="优先级：">
          <el-input v-model="newChannelData.weight" placeholder="请输入优先级" />
        </el-form-item>
        <el-form-item label="绑定支付方式：">
          <el-select v-model="newChannelData.payment_methods" placeholder="请选择支付方式" style="width:100%" multiple>
            <el-option v-for="item in paymethod" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="newDialog = false">取消</el-button>
        <el-button type="primary" @click="handleNewChannelData(ruleNewChannel)">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- New PayChannel END-->

  <!-- Config PayChannel -->
  <el-dialog v-model="configDialog" title="基础信息配置">
    <div class="dialog">
      <el-form ref="ruleConfig" :model="configDate" label-position="right" :rules="rules" label-width="150px">
        <div v-if="configDate.id == 4 || configDate.code == 'shuipiaotong'">
          <el-form-item label="AppID:" prop="config.app_id" :rules="rules.app_id">
            <el-input v-model="configDate.config.app_id" placeholder="请输入AppID" clearable />
          </el-form-item>
          <el-form-item label="AppSecret:" prop="config.app_secret" :rules="rules.app_secret">
            <el-input v-model="configDate.config.app_secret" placeholder="请输入AppSecret" clearable />
          </el-form-item>
        </div>
        <div v-if="configDate.id == 3 || configDate.code == 'vbill'">
          <el-form-item label="OrgID:" prop="config.org_id" :rules="rules.org_id">
            <el-input v-model="configDate.config.org_id" placeholder="请输入OrgID" clearable />
          </el-form-item>
          <el-form-item label="PrivateKEY:" prop="config.private_key" :rules="rules.private_key">
            <el-input v-model="configDate.config.private_key" placeholder="请输入PrivateKEY" clearable type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }" />
          </el-form-item>
          <el-form-item label="PublicKEY:" prop="config.public_key" :rules="rules.public_key">
            <el-input v-model="configDate.config.public_key" placeholder="请输入PublicKEY" clearable type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }" />
          </el-form-item>
        </div>
        <div v-if="configDate.id == 2 || configDate.code == 'wxpay'">
          <el-form-item label="AppID:" prop="config.app_id" :rules="rules.app_id">
            <el-input v-model="configDate.config.app_id" placeholder="请输入AppID" clearable />
          </el-form-item>
          <el-form-item label="MchID:" prop="config.mch_id" :rules="rules.mch_id">
            <el-input v-model="configDate.config.mch_id" placeholder="请输入MuchID" clearable />
          </el-form-item>
          <el-form-item label="KEY:" prop="config.key" :rules="rules.key">
            <el-input v-model="configDate.config.key" placeholder="请输入KEY" clearable />
          </el-form-item>
          <el-form-item label="CertPath:" prop="config.cert_path" :rules="rules.cert_path">
            <el-input v-model="configDate.config.cert_path" placeholder="请输入CertPath" clearable type="textarea"
              :autosize="{ minRows: 3, maxRows: 6 }" />
          </el-form-item>
          <el-form-item label="KEYPath:" prop="config.key_path" :rules="rules.key_path">
            <el-input v-model="configDate.config.key_path" placeholder="请输入KEYPath" clearable type="textarea"
              :autosize="{ minRows: 3, maxRows: 6 }" />
          </el-form-item>
        </div>
        <div v-if="configDate.id == 1 || configDate.code == 'alipay'">
          <el-form-item label="AppID:" prop="config.app_id" :rules="rules.app_id">
            <el-input v-model="configDate.config.app_id" placeholder="请输入AppID" clearable />
          </el-form-item>
          <el-form-item label="应用私钥:" prop="config.app_secret_cert" :rules="rules.app_secret_cert">
            <el-input v-model="configDate.config.app_secret_cert" placeholder="请输入应用私钥" clearable type="textarea"
              :autosize="{ minRows: 3, maxRows: 6 }" />
          </el-form-item>
          <el-form-item label="应用公钥证书:" prop="config.app_public" :rules="rules.app_public">
            <el-input v-model="configDate.config.app_public_cert_path" placeholder="请输入应用公钥证书" clearable type="textarea"
              :autosize="{ minRows: 3, maxRows: 6 }" />
          </el-form-item>
          <el-form-item label="支付宝公钥证书:" prop="config.alipay_public" :rules="rules.alipay_public">
            <el-input v-model="configDate.config.alipay_public_cert_path" placeholder="请输入支付宝公钥证书" clearable
              type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" />
          </el-form-item>
          <el-form-item label="支付宝根证书:" prop="config.alipay_root" :rules="rules.alipay_root">
            <el-input v-model="configDate.config.alipay_root_cert_path" placeholder="请输入支付宝根证书" clearable
              type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" />
          </el-form-item>
        </div>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="configDialog = false">取消</el-button>
        <el-button type="primary" @click="handleSeveConfig(ruleConfig)">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- Config PayChannel END-->

  <!-- Editor -->
  <el-dialog v-model="editorDialog" title="修改支付渠道">
    <div class="dialog">
      <el-form ref="ruleModify" :model="editorData" :rules="rules" label-position="right" label-width="150px">
        <el-form-item label="渠道名称：" prop="name">
          <el-input v-model="editorData.name" placeholder="请输入渠道名称" disabled />
        </el-form-item>
        <el-form-item label="编码：" prop="code">
          <el-input v-model="editorData.code" placeholder="请输入渠道编码" disabled />
        </el-form-item>
        <el-form-item label="结算周期：" prop="settlement_type">
          <el-select v-model="editorData.settlement_type" placeholder="请选择结算周期" style="width:100%">
            <el-option v-for="item in settlement" :key="item.index" :label="item.settlement_type"
              :value="item.settlement_type" />
          </el-select>
        </el-form-item>
        <el-form-item label="从商户账款扣款：" prop="deduct_type">
          <el-radio-group v-model="editorData.deduct_type">
            <el-radio :label=1 size="large">是</el-radio>
            <el-radio :label=0 size="large">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="扣费比例(%)：" prop="fee_rate" v-if="editorData.deduct_type == 1">
          <el-input v-model="editorData.fee_rate" placeholder="请输入扣费比例" />
        </el-form-item>
        <el-form-item label="状态：" prop="status">
          <el-radio-group v-model="editorData.status">
            <el-radio :label=1 size="large">启用</el-radio>
            <el-radio :label=0 size="large">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序：">
          <el-input v-model="editorData.sort" placeholder="请输入排序" clearable />
        </el-form-item>
        <el-form-item label="优先级：">
          <el-input v-model="editorData.weight" placeholder="请输入优先级" clearable />
        </el-form-item>
        <el-form-item label="绑定支付方式：">
          <el-select v-model="editorData.payment_methods" placeholder="请选择支付方式" style="width:100%" multiple
            @change="hendleSelectSwitch" @remove-tag="hendleRemoveSlect">
            <el-option v-for=" item in paymethod" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <!-- editor config -->
        <div class="editor-config">
          <el-tabs>
            <el-tab-pane :v-model="1" v-for="( item, index) in editorData.payment_tabbar" :label="item.name"
              :key="index">
              <div v-if="item.id == 1 || item.code == 'alipay'">
                <el-form-item label="费率：">
                  <el-row style="width:100%">
                    <el-col :span="22">
                      <el-input v-model="item.pivot.cost" placeholder="请设置费率" clearable />
                    </el-col>
                    <el-col :span="2">
                      <span class="percentage">%</span>
                    </el-col>
                  </el-row>
                </el-form-item>
              </div>
              <div v-if="item.id == 2 || item.code == 'wxpay'">
                <el-form-item label="费率：">
                  <el-row style="width:100%">
                    <el-col :span="22">
                      <el-input v-model="item.pivot.cost" placeholder="请设置费率" clearable />
                    </el-col>
                    <el-col :span="2">
                      <span class="percentage">%</span>
                    </el-col>
                  </el-row>
                </el-form-item>
              </div>
              <div v-if="item.id == 3 || item.code == 'unionpay'">
                <el-form-item label="借记卡1000以下(含):">
                  <el-row style="width:100%">
                    <el-col :span="10">
                      <el-input v-model="item.pivot.cost.debitcard.cost" placeholder="请设置费率" clearable />
                    </el-col>
                    <el-col :span="2">
                      <span class="percentage">%</span>
                    </el-col>
                    <el-col :span="10">
                      <el-input v-model="item.pivot.cost.debitcard.total" placeholder="请设置封顶金额" clearable />
                    </el-col>
                    <el-col :span="2">
                      <span class="percentage">元</span>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item label="借记卡1000以上:">
                  <el-row style="width:100%">
                    <el-col :span="10">
                      <el-input v-model="item.pivot.cost.debitcard_outer.cost" placeholder="请设置费率" clearable />
                    </el-col>
                    <el-col :span="2">
                      <span class="percentage">%</span>
                    </el-col>
                    <el-col :span="10">
                      <el-input v-model="item.pivot.cost.debitcard_outer.total" placeholder="请设置费率" clearable />
                    </el-col>
                    <el-col :span="2">
                      <span class="percentage">元</span>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item label="信用卡1000以下(含):">
                  <el-row style="width:100%">
                    <el-col :span="10">
                      <el-input v-model="item.pivot.cost.creditcard.cost" placeholder="请设置费率" clearable />
                    </el-col>
                    <el-col :span="2">
                      <span class="percentage">%</span>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item label="信用卡1000以上:">
                  <el-row style="width:100%">
                    <el-col :span="10">
                      <el-input v-model="item.pivot.cost.creditcard_outer.cost" placeholder="请设置费率" clearable />
                    </el-col>
                    <el-col :span="2">
                      <span class="percentage">%</span>
                    </el-col>
                  </el-row>
                </el-form-item>
              </div>
              <div v-if="item.id == 4 || item.code == 'bankcard'">
                <el-form-item label="借记卡：">
                  <el-row style="width:100%">
                    <el-col :span="10">
                      <el-input v-model="item.pivot.cost.debitcard.cost" placeholder="请设置费率" clearable />
                    </el-col>
                    <el-col :span="2">
                      <span class="percentage">%</span>
                    </el-col>
                    <el-col :span="10">
                      <el-input v-model="item.pivot.cost.debitcard.total" placeholder="请设置封顶金额" clearable />
                    </el-col>
                    <el-col :span="2">
                      <span class="percentage">元</span>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item label="信用卡:">
                  <el-row style="width:100%">
                    <el-col :span="10">
                      <el-input v-model="item.pivot.cost.creditcard.cost" placeholder="请设置费率" clearable />
                    </el-col>
                    <el-col :span="2">
                      <span class="percentage">%</span>
                    </el-col>
                  </el-row>
                </el-form-item>
              </div>
              <div v-if="item.id == 5 || item.code == 'yzfpay'">
                <el-form-item label="费率：">
                  <el-row style="width:100%">
                    <el-col :span="22">
                      <el-input v-model="item.pivot.cost" placeholder="请设置费率" clearable />
                    </el-col>
                    <el-col :span="2">
                      <span class="percentage">%</span>
                    </el-col>
                  </el-row>
                </el-form-item>
              </div>
              <div v-if="item.id == 6 || item.code == 'recharge'">
                <el-form-item label="费率：">
                  <el-row style="width:100%">
                    <el-col :span="22">
                      <el-input v-model="item.pivot.cost" placeholder="请设置费率" clearable />
                    </el-col>
                    <el-col :span="2">
                      <span class="percentage">%</span>
                    </el-col>
                  </el-row>
                </el-form-item>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <!-- editor config end-->
      </el-form>
    </div>
    <template #footer>
      <span class=" dialog-footer">
        <el-button @click="editorDialog = false">取消</el-button>
        <el-button type="primary" @click="handleSeveModify(ruleModify)">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- Editor END -->

  <activity ref="activityRef" />

</template>

<style lang="less" scoped>
.topbtn,
.pagination {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-content: center;
}

.pagination {
  margin-top: 10px;
}

.control {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
}

.dialog {
  width: 80%;
}

.editor-config {
  width: 100%;
  margin: 20px 0;
  text-align: center;
}

.editor-config,
.dialog {
  margin: 0 auto;
}

.percentage {
  margin-left: 4px;
}

.tag-clearance {
  width: 60%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>